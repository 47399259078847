import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/Layout'
import Button, { PillButton } from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString, stringToUrl } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import useCases from '../../constants/useCases'
import searches from '../../constants/searches'
import { translate, countryTranslate, activeCountries } from '../../constants/translations'

import { H1, H2, H3, H4, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Typography'
import OnlineIcon from '../components/ui/OnlineIcon'
import Image from '../components/ui/Image'
import Layout from '../components/layout'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import EzoicAd from '../components/EzoicAd'

import telephones from '../../constants/telephones'

const Shape1 = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30vw;
  height: 30vw;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 300px 300px 300px 0px;
`

const Shape4 = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 30vw;
  width: 30vw;
  height: 30vw;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 0px 300px 300px 0px;
`

const Shape2 = styled(Box)`
  position: absolute;
  left: 30vw;
  top: 0;
  width: 35vw;
  height: 85vh;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 0px 0px 300px 0px;
  z-index: 1;
`

const Shape3 = styled(Box)`
  position: absolute;
  left: 65vw;
  top: 20vh;
  width: 30vw;
  height: 80vh;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 300px 0px 0px 0px;
  z-index: 1;
`

const Left = styled(Box)`
  z-index: 1;
  padding-left: 200px;
  @media (max-width: 1350px) {
    padding-left: 150px;
  }
  @media (max-width: 1300px) {
    padding-left: 100px;
    margin-top: 150px;
  }
  @media (max-width: 1000px) {
    padding-left: 24px;
    margin-top: 150px;
  }
  @media (max-width: 500px) {
    margin-top: 100px;
    padding-right: 24px;
  }
  @media (max-width: 330px) {
    margin-top: 50px;

    padding-left: 16px;
    padding-right: 16px;
  }
`

const ResponsivePaddingFlex = styled(Flex)`
  padding-left: 200px;
  padding-right: 200px;
  @media (max-width: 1350px) {
    padding-left: 150px;
    padding-right: 150px;
  }
  @media (max-width: 1300px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (max-width: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 330px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Header = styled(H1)`
  color: white;
  font-size: 60px;
  line-height: 70px;
  margin-bottom: 32px;
  @media (max-width: 500px) {
    font-size: 40px;
    line-height: 50px;
  }
`

const SubHeader = styled(H2)`
  color: white;
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
  @media (max-width: 700px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const CountryHeader = styled(H3)`
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
  @media (max-width: 700px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const FlagImage = styled.img`
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`
const CounterContainer = styled(Flex)`
  width: 20px;
  height: 20px;
  background-color: ${colors.lightGrey};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 32px;
  @media (max-width: 700px) {
    top: 8px;
    right: 18px;
  }
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
`

const Counter = ({ count }) => {
  return (
    <CounterContainer>
      <Span fontSize="12px" bold>
        {count}
      </Span>
    </CounterContainer>
  )
}

const CountryContainer = styled(Flex)`
  max-height: 120px;
  height: 120px;
  width: 120px;
  background-color: ${colors.lightGrey};
  border-radius: 24px;

  cursor: pointer;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: ${colors.lightBlueHover};
  }
  @media (max-width: 700px) {
    padding-top: 16px;
    height: 90px;
    width: 90px;
    margin: 8px;
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 330px) {
    margin: 8px;
  }
`

const Flag = ({ iso2, width = '30px', height = '30px' }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width={width} height={height} />
}

const Grid = styled(Flex)`
  flex-wrap: wrap;
  @media (max-width: 700px) {
    justify-content: center;
  }
`

const NumberGrid = styled(Flex)`
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 450px) {
    padding-left: 16px;
    padding-right: 16px;
    a {
      width: 100%;
    }
  }
`

const IconContainer = styled(Box)`
  width: 100px;
  height: 100px;
  border-radius: 24px;
  padding: 22px;
  margin-bottom: 32px;
`

const MockupStyler = styled(Flex)`
  .phoneMockup {
    position: absolute;
    top: 0;
    right: -200px;
    @media (max-width: 900px) {
      right: -100px;
    }
    @media (max-width: 700px) {
      right: -50px;
    }
    @media (max-width: 500px) {
      right: -100px;
    }
  }
  .desktopMockup {
    position: absolute;
    top: 0;
    right: -100px;
    @media (max-width: 900px) {
      right: 100px;
    }
    @media (max-width: 700px) {
      right: 0px;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }
`

const NumberContainer = styled(Flex)`
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 24px;
  padding: 22px;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 16px;
`

const NumberCount = ({ number }) => {
  return (
    <NumberContainer>
      <Text fontSize="40px" fontWeight="500" color={colors.lightBlue}>
        {number}
      </Text>
    </NumberContainer>
  )
}

const HowStep = ({ number, text }) => {
  return (
    <Flex alignItems="center" mb="32px" maxWidth="500px">
      <NumberCount number={number} />
      <Text fontSize="18px">{text}</Text>
    </Flex>
  )
}

const RowColumnResponsive = styled(Flex)`
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const HeroContainer = styled(Flex)`
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 0px 0px 100px 0px;
    height: 75vh;
    max-height: 500px;
  }
`

const BackgroundImageContainer = styled(Box)`
  @media (max-width: 500px) {
    z-index: 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 00px;
  }
  .heroPhone {
    width: 40vw;
    @media (max-width: 1000px) {
      margin-top: 100px;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
`
const UseCaseContainer = styled(ResponsivePaddingFlex)`
  @media (max-width: 500px) {
    border-radius: 100px 0px 0px 0px;
  }
`

const MobileMenu = styled(MobileContainer)`
  height: 60px;
  background-color: ${colors.darkBlue};
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
`

const TelephoneContainer = styled(Flex)`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  flex-direction: column;
  margin: 16px;
  width: 280px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 500px) {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
`

const HeaderFlagContainer = styled(Box)`
  position: absolute;
  left: -60px;
  top: 10px;
  @media (max-width: 1000px) {
    margin-top: -50px;
    display: flex;
    position: inherit;
  }
`
const HomeImage = styled(Image)`
  @media (max-width: 500px) {
    display: none;
  }
`

const BreadCrumbContainer = styled(ResponsivePaddingFlex)`
  p {
    font-weight: 300;
  }

  @media (max-width: 500px) {
    padding-top: 0px;
    p {
      font-size: 14px;
    }
  }
`

const Li = styled.li`
  display: inline-block;
`

const Ul = styled.ul`
  display: flex;
  align-items: center;
`

export const MobileContainerTest = styled(Flex)`
  display: none;
  @media (max-width: 900px) {
    display: flex;
  }
`

export const DesktopContainerTest = styled(Flex)`
  @media (max-width: 900px) {
    display: none;
  }
`

const CountryPage = ({ pageContext }) => {
  const { country, iso2, pageIso2 } = pageContext
  const countryTelephones = telephones[country]
  // useEffect(() => {
  // const s = document.createElement(`script`)
  // s.type = `text/javascript`
  // s.crossorigin = 'anonymous'
  // s.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3580818377622359'
  // s.onload = () => {
  // try {
  //   window.adsbygoogle.push({})
  // } catch (e) {}
  // try {
  //   window.adsbygoogle.push({})
  // } catch (e) {}
  // try {
  //   window.adsbygoogle.push({})
  // } catch (e) {}
  // try {
  //   window.adsbygoogle.push({})
  // } catch (e) {}
  // }
  // document.getElementsByTagName(`body`)[0].appendChild(s)
  // }, [])
  return (
    <Layout>
      <Helmet
        title={translate(`Receive SMS online ${urlToString(countryTranslate(country, iso2))} | 100% Free | 18+ Countries`, 'country.title', iso2, {
          country: urlToString(countryTranslate(country, iso2))
        })}
        htmlAttributes={{
          lang: iso2
        }}
        description={translate(
          `Temporary ${urlToString(countryTranslate(country, iso2))} phone numbers. 100% free, no registration.`,
          'country.description',
          iso2,
          {
            country: urlToString(countryTranslate(country, iso2))
          }
        )}
        url={
          iso2 === 'en'
            ? encodeURI(`https://receiveasmsonline.com/${country}/`)
            : encodeURI(`https://receiveasmsonline.com/${iso2}/${countryTranslate(country, iso2)}/`)
        }
      >
        <html lang={iso2} />
        <link rel="alternate" hrefLang="en" href={encodeURI(`https://receiveasmsonline.com/${country}/`)} />
        {activeCountries.map((iso2) => (
          <link rel="alternate" hrefLang={iso2} href={encodeURI(`https://receiveasmsonline.com/${iso2}/${countryTranslate(country, iso2)}/`)} />
        ))}
      </Helmet>
      <MobileMenu>
        <a href={`${iso2 === 'en' ? '' : `/${iso2}`}/`}>
          <Flex width="auto" alignItems="flex-end">
            <Image alt="home icon" mr="14px" width="30px" height="30px" src="/home.svg" />
          </Flex>
        </a>
        <Flex width="auto" alignItems="flex-end" onClick={() => document.getElementById('country-container').scrollIntoView()}>
          <Text bold mr="14px" color="white">
            {translate(`All numbers`, 'allnumbers', iso2)}
          </Text>
          <Image alt="number icon" mt="1px" width="30px" height="30px" src="/number.svg" />
        </Flex>
      </MobileMenu>
      <Flex justifyContent="center" alignItems="center" bg={colors.darkBlue}>
        <EzoicAd id="107" />
      </Flex>
      <HeroContainer
        width="100vw"
        height="80vh"
        justifyContent="space-between"
        bg={colors.darkBlue}
        borderRadius="0px 0px 300px 0px"
        overflow="hidden"
        position="relative"
        maxHeight="700px"
      >
        <BreadCrumbContainer position="absolute" left="0" top="0" pt="24px" alignItems="end" zIndex={1} aria-label="breadcrumbs">
          <Ul>
            <Li>
              <a href={`${iso2 === 'en' ? '' : `/${iso2}`}/`}>
                <Flex width="auto" alignItems="flex-end">
                  <HomeImage alt="home image" mb="4px" mr="14px" width="20px" height="20px" src="/home.svg" />
                  <Text bold color="white">
                    {translate(`Home`, 'home', iso2)}
                    <Span ml="16px" color="white">
                      >
                    </Span>
                  </Text>
                </Flex>
              </a>
            </Li>
            <Li>
              <Link to={`${iso2 === 'en' ? '' : `/${iso2}`}/${countryTranslate(country, iso2)}/`}>
                <Flex width="auto" alignItems="flex-end">
                  <Text bold ml="16px" color="white">
                    {urlToString(countryTranslate(country, iso2))}
                  </Text>
                </Flex>
              </Link>
            </Li>
          </Ul>
        </BreadCrumbContainer>
        <Shape1 />
        <Shape2 />
        <Shape3 />
        <Shape4 />
        <Left mt="150px">
          <Flex position="relative">
            <Header>
              <HeaderFlagContainer>
                <Flag iso2={pageIso2} width="50px" height="50px" />
              </HeaderFlagContainer>
              {translate(`Receive SMS online ${urlToString(countryTranslate(country, iso2))}`, 'country.header', iso2, {
                country: urlToString(countryTranslate(country, iso2))
              })}
            </Header>
          </Flex>
          <SubHeader>
            {translate(`Temporary ${urlToString(country)} phone numbers. 100% free, no registration.`, 'country.subheader', iso2, {
              country: urlToString(countryTranslate(country, iso2))
            })}
          </SubHeader>
        </Left>
        <BackgroundImageContainer position="relative">
          <StaticImage alt="mobile phone mockup" className="heroPhone" src="../images/heroPhone.png" />
        </BackgroundImageContainer>
      </HeroContainer>
      <ResponsivePaddingFlex marginTop="-100px" zIndex={1} id="country-container">
        <CountryHeader color="white">{translate('Select a number below:', 'selectanumber', iso2)}</CountryHeader>
      </ResponsivePaddingFlex>
      {/* <EzoicAd id="108" /> */}
      <ins
        class="adsbygoogle"
        style={{ display: 'flex', justifyContent: 'center', height: '300px' }}
        data-ad-client="ca-pub-3580818377622359"
        data-ad-slot="6103427627"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <Flex alignItems="center" flexDirection="column" zIndex={1} mb="100px">
        <NumberGrid>
          {countryTelephones.slice(0, 12).map((telephone, i) => {
            return (
              <a key={telephone} href={`${iso2 === 'en' ? '' : `/${iso2}`}/${countryTranslate(country, iso2)}/${telephone}/`}>
                <TelephoneContainer>
                  <Flex alignItems="center">
                    <Flag iso2={pageIso2} />
                    <Text width="100%" fontSize="26px" textAlign="right" mr={2}>
                      +{telephone}
                    </Text>
                    <OnlineIcon />
                  </Flex>
                  {/* <Text fontSize="12px" textAlign="right">
                    {translate(`Last message : ${Math.round(Math.random() * 8)} mins ago`, 'lastmessage', iso2, {
                      minute: Math.round(Math.random() * 8)
                    })}
                  </Text> */}
                  <Flex justifyContent="flex-end" alignItems="center">
                    <Text fontSize="12px" textAlign="right" mr="20px">
                      {translate(`Last message : ${Math.round(Math.random() * 18)} mins ago`, 'lastmessage', iso2, {
                        minute: Math.round(Math.random() * 18)
                      })}
                      {/* {translate(`${Math.round((telephone[telephone.length - 1] / 10) * 40) + 60}% upvotes`, 'upvotes', iso2, {
                        upvote: Math.round((telephone[0] / 10) * 40) + 60
                      })} */}
                    </Text>
                  </Flex>
                </TelephoneContainer>
              </a>
            )
          })}
        </NumberGrid>
        {/* <EzoicAd id="113" /> */}
        <ins
          class="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-3580818377622359"
          data-ad-slot="3880024318"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <NumberGrid>
          {countryTelephones.slice(12, -1).map((telephone, i) => {
            return (
              <a key={telephone} href={`${iso2 === 'en' ? '' : `/${iso2}`}/${countryTranslate(country, iso2)}/${telephone}/`}>
                <TelephoneContainer>
                  <Flex alignItems="center">
                    <Flag iso2={pageIso2} />
                    <Text width="100%" fontSize="26px" textAlign="right" mr={2}>
                      +{telephone}
                    </Text>
                    <OnlineIcon />
                  </Flex>
                  {/* <Text fontSize="12px" textAlign="right">
                    {translate(`Last message : ${Math.round(Math.random() * 8)} mins ago`, 'lastmessage', iso2, {
                      minute: Math.round(Math.random() * 8)
                    })}
                  </Text> */}
                  <Flex justifyContent="flex-end" alignItems="center">
                    <Text fontSize="12px" textAlign="right" mr="20px">
                      {translate(`Last message : ${Math.round(Math.random() * 18)} mins ago`, 'lastmessage', iso2, {
                        minute: Math.round(Math.random() * 18)
                      })}
                      {/* {translate(`${Math.round((telephone[telephone.length - 1] / 10) * 40) + 60}% upvotes`, 'upvotes', iso2, {
                        upvote: Math.round((telephone[0] / 10) * 40) + 60
                      })} */}
                    </Text>
                  </Flex>
                </TelephoneContainer>
              </a>
            )
          })}
        </NumberGrid>
      </Flex>
      <EzoicAd id="112" />
      <ResponsivePaddingFlex zIndex={1} flexDirection="column" alignItems="center" bg={colors.lightGrey} mb="60px">
        <H4 fontSize="32px" mb="40px">
          {translate('How to get started', 'howtogetstarted', iso2)}
        </H4>
        <RowColumnResponsive>
          <Flex flexDirection="column" alignItems="center">
            <HowStep number={1} text={translate('Select a phone number above and send an SMS to the number', 'country.how1', iso2)} />
            <HowStep number={2} text={translate('Press refresh messages and wait for your reply to appear on the page', 'home.how3', iso2)} />
            <HowStep number={'!'} text={translate('All messages are public so avoid sending personal information', 'home.how4', iso2)} />
          </Flex>
          <MockupStyler position="relative" minHeight="400px">
            <StaticImage alt="desktop mockup" className="desktopMockup" width={600} src="../images/desktopMockup.png" />
            <StaticImage alt="phone mockup" className="phoneMockup" width={500} src="../images/phoneMockup.png" />
          </MockupStyler>
        </RowColumnResponsive>
      </ResponsivePaddingFlex>
      <UseCaseContainer
        flexDirection="column"
        zIndex={1}
        mb="100px"
        id="country-container"
        bg="white"
        pt="100px"
        pb="200px"
        borderRadius="300px 0px 0px 0px"
      >
        <ins
          class="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-3580818377622359"
          data-ad-slot="8430208065"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <CountryHeader>{translate('Find numbers in other countries:', 'country.findother', iso2)}</CountryHeader>
        <Grid>
          {Object.keys(telephones).map((otherCountry) => {
            return (
              <a key={countryTranslate(otherCountry, iso2)} href={`${iso2 === 'en' ? '' : `/${iso2}`}/${countryTranslate(otherCountry, iso2)}/`}>
                <CountryContainer>
                  <Flag iso2={countryToIso2[otherCountry]} />
                  <Counter count={telephones[otherCountry].length} />
                  <Text lineHeight="18px" px={2} textAlign="center" A>
                    {urlToString(countryTranslate(otherCountry, iso2))}
                  </Text>
                </CountryContainer>
              </a>
            )
          })}
        </Grid>
        <ins
          class="adsbygoogle"
          style={{ display: 'block', marginTop: '24px' }}
          data-ad-format="autorelaxed"
          data-ad-client="ca-pub-3580818377622359"
          data-ad-slot="4678100660"
          data-full-width-responsive="true"
        ></ins>
        <Flex flexDirection="column" mt="40px">
          <H4 fontSize="32px" mb="40px">
            {translate('Other use cases:', 'otherUseCases', iso2)}
          </H4>
          <Flex flexWrap="wrap">
            {useCases.concat(searches).map((useCase) => {
              return (
                <a href={`${iso2 === 'en' ? '' : `/${iso2}`}/${stringToUrl(translate(useCase, useCase, iso2))}/`}>
                  <PillButton mb="16px" mr="16px" label={translate(useCase, useCase, iso2)} />
                </a>
              )
            })}
          </Flex>
        </Flex>
      </UseCaseContainer>
      <Footer iso2={iso2} />
    </Layout>
  )
}

export default CountryPage
